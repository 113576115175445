import React from "react";
import OurServicesBody from "../components/ourServicesBody";
import Header from "../components/Header";
import Footer from "../components/Footer";
function OurServices() {
  return (
    <div>
      <Header />
      <OurServicesBody />
      <Footer />
    </div>
  );
}

export default OurServices;
