import React from "react";

export default function Phone2() {
  return (
    <div>
      <img
        src={require("./Phone2.png")}
        alt="Phone"
        style={{ height: "70vh" }}
      />
    </div>
  );
}
