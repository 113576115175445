import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
//This is the email form located on the contact us page.
function ContactUs() {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    //The API keys for email.js are hidden using .env
    emailjs
      .sendForm(
        process.env.REACT_APP_SERV_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_API_EMAIL_KEY
      )
      .then(
        (result) => {
          //On successful submition of the form, an alert window appers showing the
          alert(
            "Success! Thank you for contacting us. You should be recieving a reply from us shortly."
          );
        },
        (error) => {
          alert("Sorry! There was an error, please try again");
        }
      );
    //This resets the fields on the form back to blank after they submit
    e.target.reset();
  };
  return (
    <div className="wrapper row">
      <div className="centerText">
        <form ref={form} onSubmit={sendEmail}>
          <input
            type="text"
            name="user_name"
            className="contactFormInput"
            placeholder="Full Name"
            required
          />
          <input
            type="email"
            name="user_email"
            className="contactFormInput"
            placeholder="Your Email"
            required
          />
          <input
            type="text"
            name="user_subject"
            className="contactFormInput"
            placeholder="Subject"
            required
          />
          <textarea
            name="message"
            className="contactFormMessage"
            placeholder="Your Message"
            required
          />
          <div>
            <input
              type="submit"
              className="btn btn-primary"
              data-testid="ContactFormSubmit"
              value="Send"
            />
          </div>
        </form>
      </div>
    </div>
  );
}
export default ContactUs;
