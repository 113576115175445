import React, { useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import AboutBody from "../components/aboutUsBody";
//Creates About us component
function AboutUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header />
      <AboutBody style={{ height: "100vh" }} />
      <Footer />
    </div>
  );
}
//Exports About us component

export default AboutUs;
