import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import AboutUs from "./pages/aboutUs";
import ContactPage from "./pages/contactPage";
import OurServices from "./pages/ourservices";
import TempPage from "./pages/404";
import LandingPage from "./pages/landingPage";
//These imports declare each page as variable to be rendered using react router

//Declares routes and the elements rendered
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* Route to Home Page */}
          <Route path="/" element={<TempPage />}></Route>
          {/* Route to about us page */}
          <Route path="about_us" element={<AboutUs />}></Route>
          {/* Route to Our services page */}
          <Route path="ourServices" element={<OurServices />}></Route>
          {/* Route to contact page */}
          <Route path="contactpage" element={<ContactPage />}></Route>
          {/* If a unknown URL is accessed, Redirect to /404 */}
          <Route path="ComingSoon" exact element={<LandingPage />}></Route>
          <Route path="*" exact element={<Navigate to="/temp" />}></Route>
          {/* /404 page. FourOFour component is rendered with a link to return Home */}
          <Route path="/temp" exact element={<TempPage />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}
//Exports App component
export default App;
