import React, { useEffect } from "react";
import Phone from "./partials/Phone";
import Phone2 from "./partials/Phone2";
export default function LandingPageBody() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="centerText">
        <div style={{ borderRadius: 20 }}>
          <h1>Reads Made Easy</h1>
        </div>
      </div>

      <div
        className="centerText container"
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          flexWrap: "wrap",
          position: "relative",
        }}
      >
        <div>
          <h2>Easily Submit Utility Reads</h2>
          <Phone />
        </div>
        <div>
          <h2>Submit Photos For Each Read</h2>
          <Phone2 />
        </div>

        <div
          style={{
            borderRadius: 10,
            width: "100vw",
            backgroundColor: "#048dc9",
            marginBottom: 10,
          }}
        >
          <div
            style={{
              padding: 50,
              margin: 10,
              borderRadius: 10,
              backgroundColor: "black",
              color: "#fff",
              opacity: 0.7,
            }}
          >
            <h1>Coming Soon to the Google Play and App store</h1>
            <i class="fa-brands fa-google-play fa-4x"></i>
            <i class="fa-brands fa-app-store-ios fa-4x"></i>
          </div>
        </div>
      </div>
    </div>
  );
}
