import React from "react";

//The Footer for the contact page contains the same columns as the regular Footer
//The columns are still there just inase any copy needs to be added to this Footer later on
function ContactFooter() {
  return (
    <div className="footer" style={{ background: "#252525" }}>
      <section id="footer">
        <div className="row">
          <footer id="footer" className="footer-1">
            
              <div >
                <div className="widget no-box ">
                  <br />
                  <h5 className="widget-title ">
                    <span></span>
                  </h5>
                  <ul>
                    <div className="container vcsfoot">
                      <img
                        src="https://ik.imagekit.io/39dbkqr1gb/VCSWhite.webp"
                        alt=""
                        style={{ width: "auto", height: "90px" }}
                        className="img-fluid footer-pic"
                      />
                    </div>
                  </ul>
                </div>
              </div>
           
            <div className="footer-copyright">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 text-center">
                    <p>Copyright VCSBilling © 2023. All rights reserved.</p>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </section>
    </div>
  );
}
export default ContactFooter;
