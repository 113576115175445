import React, { useEffect } from "react";
import Header from "../components/Header";
import ContactFooter from "../components/ContactFooter";

import ContactUs from "../components/contactForm";


//CSS for background is inline and camelCased to be rendered by react
// const backgroundImg = {
//   backgroundAttachment: 'fixed',
//   backgroundPosition:"center",
//   backgroundSize:'cover',
//   backgroundRepeat:'no-repeat',
//   background: 'linear-gradient(to right, rgba(102, 212, 234, 0.55), rgba(253, 150, 100, 0.55)) !important',
//   // backgroundImage: 'url(' + require('https://ik.imagekit.io/39dbkqr1gb/waterBackground.webp') + ')'

// }
function ContactPage() {
  //Scrolls to top of page when navigated to from Link found somewhere other than Header.jsx
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />
      <div className="container-fluid contactus contactBackground ">
        <div className="row contactStuff wrapper" style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
          <div className="col-lg-6 content">
            <ul>
              <div className="widget no-box">
                <h5 className="widget-title">
                  Contact Us<span></span>
                </h5>
              </div>
            </ul>
            <ul>
              <li>(800) 379-7426 Toll-Free</li>
              <li>(951) 929-3800 Local</li>
              <li>(951) 929-3899 Fax</li>
            </ul>
            <ul>
              <h5>Mailing Address </h5>
              <li>PO Box 2747</li>
              <li>Hemet, California 92546</li>
            </ul>
            <ul>
              <h5>Office Address</h5>
              <li>151 North Gilbert Street</li>
              <li>Hemet, California 92543</li>
            </ul>
          </div>
          
        </div>
        {/* Contact page Email form */}
        <ContactUs />
      </div>
      {/* Contact page footer */}
      <ContactFooter />
    </div>
  );
}
export default ContactPage;
