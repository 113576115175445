import React from "react";
import { Link } from "react-router-dom";
import Logo from "./partials/Svg";
//Create navbar component under the title of Header
function Header() {
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-dark  bg-dark">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            {/* <img
              className="vcs-logo"
              style={{ width: "70%", height: "80%" }}
              src="https://ik.imagekit.io/39dbkqr1gb/tr:w-100/navbarLogo.webp"
              alt="VCS"
            /> */}
            <Logo style={{width: "70%", height: "50%"}}/>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link
                  className="nav-link active"
                  data-testid="HomeBtn"
                  aria-current="page"
                  to="/"
                >
                  <button type="button" className="btn btn-outline-primary">
                   <strong> Home </strong>
                  </button>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  data-testid="AboutUsBtn"
                  to="/about_us"
                >
                  <button type="button" className="btn btn-outline-primary">
                   <strong> About Us </strong> 
                  </button>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  data-testid="ourServ"
                  to="/ourServices"
                >
                  <button type="button" className="btn btn-outline-primary">
                  <strong> Our Services </strong> 
                  </button>
                </Link>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-testid="PayRent"
                  href="https://vcs.payrent.com"
                >
                  <button type="button" className="btn btn-outline-primary">
                  <strong> Pay Your Bill </strong> 
                  </button>
                </a>
              </li>
              <li className="nav-item dropdown"></li>
            </ul>
            <Link
              className=" btn btn-outline-primary"
              data-testid="contactBtn"
              to="/contactpage"
            >
            <strong>Contact Us</strong>  
            </Link>
          </div>
        </div>
      </nav>
    </div>
  );
}
//Exports the navbar as header
export default Header;
