import React from "react";
import { motion } from "framer-motion";
function OurServicesBody() {
  return (
    <div>
      <div className="ourServices bodyParentDiv">
        <div className="container servicesParentDiv">
          <div className="container servicesOverview wrapper">
            <div className="col-lg-12 centerText servicesHeader">
              <img
                src="https://ik.imagekit.io/39dbkqr1gb/whiteC.webp"
                className="ourServC"
                alt="whiteC"
              />
              <h1>Our Services</h1>
            </div>
            <div className="row">
              <motion.div
                className="col centerText"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ ease: "anticipate", duration: 1 }}
              >
                <h5>
                  <i className="fa-solid fa-house"></i> MULTI UNIT SOFTWARE
                </h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  lacinia ac lectus quis porta. Nam vehicula sit amet mi a
                  suscipit. Lorem ipsum dolor sit amet, consectetur adipiscing
                  elit. Sed lacinia ac lectus quis porta. Nam vehicula sit amet
                  mi a suscipit.{" "}
                </p>
              </motion.div>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ ease: "anticipate", duration: 1 }}
                className="col centerText"
              >
                <h5>
                  {" "}
                  <i className="fa-solid fa-clipboard-check"></i> REGULATORY
                  CONFORM
                </h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  lacinia ac lectus quis porta. Nam vehicula sit amet mi a
                  suscipit. Lorem ipsum dolor sit amet, consectetur adipiscing
                  elit. Sed lacinia ac lectus quis porta. Nam vehicula sit amet
                  mi a suscipit.{" "}
                </p>
              </motion.div>
            </div>
            <div className="row">
              <motion.div
                className="col centerText"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ ease: "anticipate", duration: 1.5 }}
              >
                <h5>
                  <i className="fa-solid fa-headset"></i> PERSONAL CUSTOMER
                  SUPPORT
                </h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  lacinia ac lectus quis porta. Nam vehicula sit amet mi a
                  suscipit: (800) 379-7426 (Toll Free) or (951) 929-3800 (Local){" "}
                </p>
              </motion.div>
              <motion.div
                className="col centerText"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ ease: "anticipate", duration: 1.5 }}
              >
                <h5>
                  <i className="fa-solid fa-building"></i> MANUFACTURED HOUSING
                  SPECIALIST
                </h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  lacinia ac lectus quis porta. Nam vehicula sit amet mi a
                  suscipit. Lorem ipsum dolor sit amet, consectetur adipiscing
                  elit. Sed lacinia ac lectus quis porta. Nam vehicula sit amet
                  mi a suscipit.{" "}
                </p>
              </motion.div>
            </div>
            <div className="row">
              <motion.div
                className="col centerText"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ ease: "anticipate", duration: 2 }}
              >
                <h5>
                  <i className="fa-solid fa-user-tie"></i> PROFFESIONAL SERVICES
                  SINCE 1980
                </h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  lacinia ac lectus quis porta. Nam vehicula sit amet mi a
                  suscipit. Lorem ipsum dolor sit amet, consectetur adipiscing
                  elit. Sed lacinia ac lectus quis porta. Nam vehicula sit amet
                  mi a suscipit.{" "}
                </p>
              </motion.div>
              <motion.div
                className="col centerText"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ ease: "anticipate", duration: 2 }}
              >
                <h5>
                  <i className="fa-solid fa-file-contract"></i> TAILORED TENANT
                  SOLUTIONS
                </h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  lacinia ac lectus quis porta. Nam vehicula sit amet mi a
                  suscipit. Lorem ipsum dolor sit amet, consectetur adipiscing
                  elit. Sed lacinia ac lectus quis porta. Nam vehicula sit amet
                  mi a suscipit.{" "}
                </p>
              </motion.div>
            </div>
            <div className="row">
              <motion.div
                className="col centerText"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ ease: "anticipate", duration: 2.5 }}
              >
                <h5>
                  <i className="fa-solid fa-stopwatch"></i> TIME SAVING
                  PROCESSES
                </h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  lacinia ac lectus quis porta. Nam vehicula sit amet mi a
                  suscipit. Lorem ipsum dolor sit amet, consectetur adipiscing
                  elit. Sed lacinia ac lectus quis porta. Nam vehicula sit amet
                  mi a suscipit.{" "}
                </p>
              </motion.div>
              <motion.div
                className="col centerText"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ ease: "anticipate", duration: 2.5 }}
              >
                <h5>
                  <i className="fa-solid fa-face-smile"></i> 10,000 SATISFIED
                  CUSTOMERS
                </h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  lacinia ac lectus quis porta. Nam vehicula sit amet mi a
                  suscipit. Lorem ipsum dolor sit amet, consectetur adipiscing
                  elit. Sed lacinia ac lectus quis porta. Nam vehicula sit amet
                  mi a suscipit.{" "}
                </p>
              </motion.div>
            </div>
            <div className="row justify-content-center">
              <motion.div
                className="col-6 centerText"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ ease: "anticipate", duration: 2.5 }}
              >
                <h5>
                  <i className="fa-solid fa-file-invoice-dollar"></i> ONLINE
                  PAYMENTS
                </h5>
                <p>
                  We provide a simple and convenient way for residents to make
                  payments through our{" "}
                  <a
                    href="https://vcs.payrent.com/about"
                    target="_blank"
                    rel="noreferrer"
                    className="text-white"
                  >
                    online portal
                  </a>
                  . Accepting digital payments improves cashflow, minimizes
                  failed payments and increases resident satisfaction.
                </p>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurServicesBody;
