import React from "react";
import Header from "../components/Header";
import LandingPageBody from "../components/landingPageBody";
import Footer from "../components/Footer";
export default function LandingPage() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <Header />
      <LandingPageBody />
      <div style={{position:"relative"}}>
      <Footer isOnLandingPage={true} />
      </div>
    </div>
  );
}
