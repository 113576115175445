import React from "react";

export default function Phone() {
  return (
    <div>
      <img
        src={require("./Phone.png")}
        alt="Phone"
        style={{ height: "70vh" }}
      />
    </div>
  );
}
