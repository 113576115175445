import React from "react";

import { Link } from "react-router-dom";
// import VCSLogo from '../img/navbarLogo.webp'
// Custom css using react syntax
// Background css
const backgroundImg = {
  backgroundAttachment: "fixed",
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  background:
    "linear-gradient(to right, rgba(102, 212, 234, 0.55), rgba(253, 150, 100, 0.55)) !important",
  backgroundImage: "url(" + require("../img/waterBackground.webp") + ")",
  height: "100vh",
  minWidth: "100vw",
};
//css for Logo
const logo = {
  height: "100px",
};
//css for container on page
const con = {
  paddingTop: "20vh",
  height: "100%",
};
// change back to FourOFour
function TempPage() {
  //   <div style={con} className="wrapper centerText">
  // <h1 className="centerText">404</h1>
  // <h1 className="centerText">Page Not Found</h1>
  // <br />
  // <img
  //   style={logo}
  //   src="https://ik.imagekit.io/39dbkqr1gb/tr:w-800/navbarLogo.webp"
  //   alt="vcsLogo"
  // ></img>
  // <br />
  // <br />
  // <h5>
  //   The page you were looking for could not found or it does not exist.
  //   Please return to the home page and try your request again.
  // </h5>
  // <br />
  // <Link to="/">
  //   <button data-testid="404Link" className="btn btn-outline-primary ">
  //     Return Home
  //   </button>
  // </Link>
  // <br />
  // <br />
  // <p>
  //   If you think this is a mistake,{" "}
  //   <Link to="/contactPage" data-testid="404toContact">
  //     please contact us.
  //   </Link>
  // </p>
  // </div>
  return (
    <>
      <div style={backgroundImg} className="container-fluid">
        <div style={con} className="wrapper centerText">
          <h1 className="centerText">Currently Under Construction</h1>
          <br />
          <img
            style={logo}
            src="https://ik.imagekit.io/39dbkqr1gb/VCS_Logo_-_C_Icon_Tight.png?updatedAt=1683585245726"
            alt="vcsLogo"
          ></img>
          <br />
          <br />
          <h5>
            Please return at a later time or contact us for more information.
          </h5>
          <br />

          <br />
          <br />
          <p></p>
        </div>
      </div>
    </>
  );
}
export default TempPage;
